<template>
  <el-container style="height: 100vh; border: 1px solid #eee">
    <el-aside width="200px" style="background-color: rgb(238, 241, 246)">
      <el-menu router :default-active="$route.path">
        <el-submenu index="1">
          <template slot="title">
            <i class="el-icon-message"></i>
            Links
          </template>
          <el-menu-item-group>
            <template slot="title"></template>
            <el-menu-item index="/lead/list"> Lead List</el-menu-item>
          </el-menu-item-group>
          <el-menu-item-group>
            <template slot="title"> </template>
            <el-menu-item index="/contact-us/list">Contact List</el-menu-item>
          </el-menu-item-group>
          <el-menu-item-group>
            <template slot="title"> </template>
            <el-menu-item index="/purchase/list">Purchase List</el-menu-item>
          </el-menu-item-group>
          <el-menu-item-group>
            <template slot="title"> </template>
            <el-menu-item index="/subscribe/list">Subscribe List</el-menu-item>
          </el-menu-item-group>
         
        </el-submenu>
      
      </el-menu>
    </el-aside>
    <el-container>
      <el-header style="text-align: right; font-size: 12px">
        <span>{{ company }}</span>
        <el-dropdown>
          <i class="el-icon-setting" style="margin-left: 10px"></i>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item>
              <a @click="logout">Logout</a>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </el-header>

      <el-main>
        <router-view :key="$route.path"></router-view>
      </el-main>
    </el-container>
  </el-container>
</template>

<style lang="scss" scoped>
.el-header {
  background-color: #69a8e7;
  color: #333;
  line-height: 60px;
}

.el-aside {
  color: #333;
}
</style>

<script>
export default {
  data() {
    return {
      company: '',
    }
  },
  methods: {
    logout() {
      localStorage.clear()
      this.$router.push('/login')
    },
  },
  created() {
    this.company = localStorage.getItem('company') || ''
  },
}
</script>
